<template>
  <div class="navbar">
    <!-- 左侧返回键 -->
    <div class="navbar-back" v-if="showBack" @click="goBack">
      <img src="@/assets/icon/back_white.png" alt="" v-if="color == 'white'">
      <img src="@/assets/icon/back.png" alt="" v-else>
    </div>
    <!-- 标题 -->
    <div :class="color == 'white' ? 'white-title navbar-title' : 'navbar-title'" v-if="showTitle">{{ $route.name }}</div>
    <!-- 右侧自定义图标 -->
    <div class="navbar-icon">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showBack: {
      default: true,
      type: Boolean
    },
    showTitle: {
      default: true,
      type: Boolean
    },
    color: {
      default: 'black',
      type: String
    }
  },
  data() {
    return {

    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped lang="scss">
.navbar {
  padding: 0 .23rem;
  padding-top: .30rem;
  position: relative;
  font-size: .32rem;
  line-height: .45rem;
  height: .75rem;

  

  .navbar-back {
    height: .45rem;
    display: flex;
    position: absolute;
    left: .23rem;
    top: .32rem;
    cursor: pointer;

    img {
      margin-top: .09rem;
      width: .3rem;
      height: .26rem;
    }
  }

  .navbar-icon {
    height: .45rem;
    position: absolute;
    right: .23rem;
    top: .32rem;
  }

  .navbar-title {
    padding: 0 1.6rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: .32rem;
    text-align: center;
    line-height: .45rem;
    color: #000000;
  }
  .white-title {
    color: #fff;
  }
}
</style>