import {
	http
} from "../request"
// 订单列表
const orderList = (data) => {
	return http("/front/mall/goods/order/orderList", data)
}
// 生成待支付订单
const orderPre = (data) => {
	return http("/front/mall/goods/order/preOrder", data)
}
// 生成待支付订单(积分)
const orderPoints = (data) => {
	return http("/front/mall/goods/order/pointOrder", data)
}
// 支付订单
const orderPay = (data) => {
	return http("/front/mall/goods/order/payOrder", data)
}
// 订单详情
const orderDetail = (data) => {
	return http("/admin/mall/goods/order/find/id", data)
}


export default {
    orderList,
    orderPre,
	orderPoints,
    orderDetail,
    orderPay
}