//二级路由
const router = [
    {
    path: '',
    name: "Shopping",//首页商城,
    component: () => import('../../views/shop/index.vue')
  },
  {
    path: 'cate',
    name: "Commodity classifica",//分类,
    component: () => import('../../views/cate/index.vue')
  },
  {
    path: 'home',
    name: "Account",//个人中心,
    component: () => import('../../views/home/index.vue')
  },
  
]

export default router
