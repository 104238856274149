<template>
    <div class="tabbar">
        <!-- 商城 -->
        <div class="tabbar-item">
            <div class="item-box" @click="changIndex(0)">
                <!-- 图片 -->
                <div class="tabbar-icon" v-if="index == 0">
                    <img src="@/assets/icon/index_active.png" alt="">
                </div>
                <div class="tabbar-icon" v-else>
                    <img src="@/assets/icon/index.png" alt="">
                </div>
                <!-- 标题 -->
                <div :class="index == 0 ? 'tabbar-title tabbar-active' : 'tabbar-title'">Shopping</div>
            </div>
        </div>
        <!-- 分类 -->
        <!-- <div class="tabbar-item">
            <div class="item-box" @click="changIndex(1)">
                <div class="tabbar-icon" v-if="index == 1">
                    <img src="@/assets/icon/cate_active.png" alt="">
                </div>
                <div class="tabbar-icon" v-else>
                    <img src="@/assets/icon/cate.png" alt="">
                </div>
                <div :class="index == 1 ? 'tabbar-title tabbar-active' : 'tabbar-title'">Classify</div>
            </div>
        </div> -->
        <!-- 个人中心 -->
        <div class="tabbar-item">
            <div class="item-box" @click="changIndex(2)">
                <div class="tabbar-icon" v-if="index == 2">
                    <img src="@/assets/icon/my_active.png" alt="">
                </div>
                <div class="tabbar-icon" v-else>
                    <img src="@/assets/icon/my.png" alt="">
                </div>
                <div :class="index == 2 ? 'tabbar-title tabbar-active' : 'tabbar-title'">Account</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            index: 0
        }
    },
    methods: {
        // 切换tabbar
        changIndex(index) {
            this.index = index
            if (index == 0) {
                this.$router.push("/")
            }
            if (index == 1) {
                this.$router.push("/cate")
            }
            if (index == 2) {
                this.$router.push("/home")
            }
        }
    },
    mounted() {
        if (this.$route.path=="/") { 
            this.index = 0
        }
        if (this.$route.path=="/cate") { 
            this.index = 1
        }
        if (this.$route.path=="/home") { 
            this.index = 2
        }
    }
}
</script>
<style scoped lang="scss">
.tabbar-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: .2rem;
    color: #000000;
    line-height: .28rem;
    text-align: center;
}

.tabbar-active {
    color: #FF3510;
}

.tabbar-icon {
    display: flex;
    margin: 0 auto;
    justify-content: center;
    width: .42rem;
    height: .42rem;
    margin-bottom: .05rem;
}

.tabbar {
    display: flex;
    // 暂时砍掉分类，居中显示
    justify-content: space-around;
    padding-top: .16rem;
    padding-bottom: .68rem;
    background-color: #fff;
    box-shadow: inset 0px 1 0px 0px #D8D8D8;
    border-top: .01rem solid #eee;
    position: relative;
    z-index: 100000;

    .tabbar-item {
        width: 33.333%;
        display: flex;
        justify-content: center;

    }
    .item-box{
        cursor: pointer;
    }
}
</style>