import Vue from "vue"
import vonload from "./view/onload.vue"
import navbar from "./view/navbar.vue"
import tabbar from "./view/tabbar.vue"


let obj = {
   vonload,
   navbar,
   tabbar
}

for(let i in obj){
  Vue.component(i,obj[i])
}