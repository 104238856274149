import {
	http
} from "../request"
// 注册
const userRegister = (data) => {
	return http("/front/user/register", data)
}
// 登录
const userLogin = (data) => {
	return http("/front/user/login", data)
}
// 退出登录
const userLogout = (data) => {
	return http("/front/user/logout", data)
}
// 用户详情
const userInfo = (data) => {
	return http("/front/user/current", data)
}
// 用户修改
const userEdit = (data) => {
	return http("/front/user/set-user-info", data)
}
// 重置密码
const resetPassword = (data) => {
	return http("/front/user/reset/password", data)
}
// 发送验证码(找回密码用)
const sendEmail = (data) => {
	return http("/front/user/send/email-code", data)
}
// 发送验证码(注册用)
const sendRegEmail = (data) => {
	return http("/front/user/send/reg-email-code", data)
}

// 通过邮箱和验证码重设密码
const resetPwd = (data) => {
	return http("/front/user/reset/password", data)
}
// 粉丝列表
const fansCurrent = (data) => {
	return http("/front/user/point/fansCurrent", data)
}







export default {
	userRegister,
	sendRegEmail,
	userLogin,
	userLogout,
	userInfo,
	userEdit,
	resetPassword,
	sendEmail,
	resetPwd,
	fansCurrent
}