import axios from "axios";
import store from "@/store/index";
import router from "../router";
import { Toast } from 'vant';

let baseUrl =""
// 开发环境
if(process.env.NODE_ENV === 'development'){
  baseUrl = "/api"
  //baseUrl = "http://18.144.46.10/mall-api"

}else{
  baseUrl = "https://api.knowworld.org/mall-api"
}
// 请求拦截
axios.interceptors.request.use(
  req => {
    if (req.method == "post" && !req.onload) {
      req.headers["Content-Type"] = "application/json;charset=utf-8";
      req.data = JSON.stringify(req.data);
    }
    req.headers.token = store.state.user.token || "";
    if (req.loading === "true") {
      Toast.loading({
        message: 'Loading',
        forbidClick: true,
      });
    }
    return req;
  },
  err => {
    Toast.fail('Request error');
    return Promise.reject(new Error("Request error"));
  }
);
// 响应拦截
axios.interceptors.response.use(
  res => {
    Toast.clear()
    const { code, message } = res.data;
    if (res.status == 200) {
      if (code == 200) {
        return res.data;
      } else if (code == 401) {
        Toast.fail('login timeout ');
        store.commit("user/changeUserToken", "");
        router.replace("/login");
        return Promise.reject(new Error("login timeout "));
      } else {
        Toast.fail(message.message);
        return Promise.reject(new Error(message.message));
      }
    } else {
      Toast.fail('Server error!');
      return Promise.reject(new Error("Server error!"));
    }
  },
  error => {
    Toast.clear()
    Toast.fail('Server error!');
    return Promise.reject(new Error("Server error!"));
  }
);

const http = (url, form, method = "post", loading = "true") => {
  return axios({
    url: baseUrl + url,
    method,
    [method.toLowerCase() === "get" ? "params" : "data"]: form,
    loading
  });
};
// 多文件上传
const uploadAll = (url, file, method = "post", loading = "true") => {
  let data = new FormData();
  // for (let key in file) {
  //   data.append("file", file[key])
  // }
  data.append("file", file[0]);
  return axios({
    url: baseUrl + url,
    method,
    data: data,
    loading,
    onload: true
  });
};

export { http, uploadAll };
