// 数据持久化插件
import createPersistedstate from 'vuex-persistedstate'
import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)

import user from "./modules/user"


export default new Vuex.Store({
  modules: {
    user
  },
  // 配置数据持久化插件，数据会存进缓存
  plugins: [
    createPersistedstate({
      // 本地存储名称
      key: "Metadatalinker-store",
      //指定要存储的模块
      paths: ["user"]
    })
  ]
})
