import {
	http
} from "../request"
// 商品列表
const goodsList = (data) => {
	return http("/front/mall/goods/page", data)
}
// 商品轮播
const goodsBanner = (data) => {
	return http("/front/common/setting", data)
}
// 商品详情
const goodsDetail = (data) => {
	return http("/admin/mall/goods/find/id", data)
}





export default {
    goodsList,
    goodsBanner,
    goodsDetail
}