import { render, staticRenderFns } from "./onload.vue?vue&type=template&id=67640250&scoped=true"
import script from "./onload.vue?vue&type=script&lang=js"
export * from "./onload.vue?vue&type=script&lang=js"
import style0 from "./onload.vue?vue&type=style&index=0&id=67640250&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67640250",
  null
  
)

export default component.exports