import {
  uploadAll
} from "../request"

// 多文件上传，服务器暂时支持一个
const uploadFile = (data) => {
  return uploadAll("/admin/common/upload", data)
}


export default {
  uploadFile
}
