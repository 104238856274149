// 引入接口
import api from "@/api/index.js"
// 存放数据
const state = {
  token: "",
  // 详情
  info: {},
  setting: {
    usdtAddress: "",
    usdtAddressUrl: ""
  },
  code:""
}

// 修改数据
const mutations = {
  changeUserInfo(state, info) {
    // 修改状态层userInfo
    state.info = info
  },
  changeUserToken(state, token) {
    // 修改状态层token
    state.token = token
  },
  changeSetting(state, setting) {
    // 修改状态层setting
    state.setting = setting
  },
  changeCode(state, code) {
    // 修改状态层code
    state.code = code
  },
  
}

// 请求
const actions = {
  reqUserInfo(context, data) {
    api.userInfo().then(res => {
      context.commit('changeUserInfo', res.data)
    }).catch(err => {
      console.log(err)
    })
  },
  reqUserToken(context, token) {
    context.commit('changeUserToken', token)
  },
  reqSetting(context, setting) {
    context.commit('changeSetting', setting)
  },
  reqCode(context, code) {
    context.commit('changeCode', code)
  }
}

// 导出数据
const getters = {
  userInfo(state) {
    return state.info
  },
  userToken(state) {
    return state.token
  },
  setting(state){
    return state.setting
  },
  code(state){
    return state.code
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
  // 命名空间
  namespaced: true
}
