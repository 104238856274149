
// 验证电话
const isphone = (phone) => {
	let phoneReg = /^(((13[0-9]{1})|(15[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
	return phoneReg.test(phone)
}
// 验证邮箱
const isemail = (email) => {
	let emailReg = /^([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\_|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;
	return emailReg.test(email)
}
// 身份证验证
const isCard = (idCard) => {
	let cardReg =
		/^[1-9][0-9]{5}(19|20)[0-9]{2}((01|03|05|07|08|10|12)(0[1-9]|[1-2][0-9]|3[0-1])|(04|06|09|11)(0[1-9]|[1-2][0-9]|30)|02(0[1-9]|[1-2][0-9]))[0-9]{3}([0-9]|x|X)$/;
	return cardReg.test(idCard)
}




export default {
	isphone,
	isemail,
	isCard
}