<template>
    <!-- 视频分片上传组件 -->
    <div class="onload-content">
        <div style="display: flex;">
            <div class="onload-box">
                <div class="add">+</div>
                <video :src="url" controls="controls" v-if="url"></video>
                <input ref="videoFile" id="file" @change="changeVideo" accept="video/mp4" type="file" :multiple="false">
            </div>
            <div style="display: flex;flex-direction: column;justify-content: flex-end;">
                <div style="color: red;margin-left: 20px;">注：请选择小于200M的MP4文件</div>
            </div>
        </div>
        <!-- 进度条 -->
        <div class="progress-box" v-if="showLine">
            <div>上传进度：</div>
            <el-progress class="progress" :percentage="percentage"></el-progress>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            // 进度条
            percentage: 0,
            // 进度条显示
            showLine: false,
            // 成功时返回的链接
            url: "",
            // 上传文件唯一标识
            resFileKey: "",
            // 停止上传，关闭弹窗时生效
            stopOnload: false,
            // 禁止在上传时重新上传
            isOnload: false
        }
    },
    methods: {
        // 监听进度条改变颜色改变
        customColorMethod() {
            if (this.percentage < 20) {
                return '#FC4511';
            } else if (this.percentage < 40) {
                return '#F68516 ';
            } else if (this.percentage < 60) {
                return '#F7F804 ';
            }
            else if (this.percentage < 80) {
                return '#99F804  ';
            } else {
                return '#67c23a';
            }
        },
        // 视频上传
        changeVideo(e) {
           // 正在上传时不允许再次选取
            if (this.isOnload) {
                // Message.warning("正在上传文件，请稍后再试！")
                return
            }
            let file = e.target.files[0];
            // 限制视频大小为200兆
            if (file && file.size > (200 * 1024 * 1024)) {
                // Message.warning("视频文件大小超过200M")
                return
            }
            if (file) {
                // 上传至服务器
                this.onloadVideo(file, 1, "")
            }
        },
        // 分片
        onloadVideo(file, number, fileKey) {
            // 关闭弹窗中断上传
            if (this.stopOnload) return
            // 默认每片4兆
            let oneSize = 4 * 1024 * 1024
            // 总片数向上取整
            let sectionNum = Math.ceil(file.size / oneSize)
            // 开始位置
            let start = (number - 1) * oneSize
            // 结束位置取最小值
            let end = Math.min(file.size, start + oneSize);
            // 截取当前需要上传的文件
            let nowFile = file.slice(start, end)
            // 判断当前片数是否小于等于总片数
            if (number <= sectionNum) {
                this.isOnload = true
                this.showLine = true
                this.$api.uploadOne({ file: nowFile, chunkNumber: number, totalChunks: sectionNum, identifier: fileKey, fileName: file.name }).then(res => {
                    // 设置进度条
                    this.percentage = Math.ceil((number / sectionNum) * 100)
                    // 上传完成
                    if (res.data.path) {
                        // 成功后清空resFileKey
                        this.resFileKey = ""
                        this.url = res.data.path
                        Message.success(res.msg)
                        // 成功后把链接传给父组件
                        this.$emit("success", this.url)
                        // 清空进度条，允许重新上传
                        this.clearData()
                    } else {
                        // 获取唯一标识
                        this.resFileKey = res.data.identify
                        // 继续上传下一片
                        this.onloadVideo(file, number + 1, this.resFileKey)
                    }
                }).catch(err => {
                    // 失败选择是否继续上传
                    this.$confirm('上传失败, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        // 点击确定继续上传当前切片
                        this.onloadVideo(file, number, fileKey)
                    }).catch(() => {
                        // 点击取消清除数据
                        this.clearData()
                        // Message.error("上传失败")
                    });
                })
            }
        },
        // 当前页面清除数据
        clearData() {
            // // 重置进度条
            this.percentage = 0
            // 关闭进度条
            this.showLine = false
           // 清空选择的文件，便于下次选择事件触发
            this.$refs.videoFile.value = ""
            // 允许重新选取
            this.isOnload = false
            // 如果resFileKey存在则表示上传至一半
            if (this.resFileKey) {
                this.clearFile()
            }
        },
        // 打开弹窗
        openWindow() {
            // 重置进度条
            this.percentage = 0
            // 允许上传
            this.stopOnload = false
        },
        // 关闭弹窗
        closeWindow() {
            // 中断递归上传
            this.stopOnload = true
            // 清空链接
            this.url = ""
            this.clearData()
        },
        // 上传中断清除文件碎片
        clearFile() {
            this.$api.clearFile({ identify: this.resFileKey }).then(res => {
                console.log(res)
            }).catch(err => {
                console.log(err)
            }).finally(() => {
                //清空resFileKey
                this.resFileKey = ""
            })
        }
    }
}
</script>

<style scoped>
.progress {
    width: 300px;
}

.progress-box {
    margin-top: 10px;
    width: 100%;
    display: flex;
    font-size: 16px;
    line-height: 16px;
}

.onload-box input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    z-index: 3;
    left: 0;
    top: 0;
}

.onload-box img {
    position: absolute;
    z-index: 2;
    object-fit: cover;
    left: 0;
    top: 0;
}

.onload-box video {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

.onload-box .add {
    position: relative;
    z-index: 1;
}

.onload-box {
    overflow: hidden;
    position: relative;
    text-align: center;
    line-height: 100px;
    width: 100px;
    font-size: 30px;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #eee;
}

@media screen and (max-width:1200px) {}
</style>