// 获取开始日期和结束日期
const getDates = (type) => {
	const date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	if (type === 'start') {
		year = year - 90;
	} else if (type === 'end') {
		year = year;
	}
	month = month > 9 ? month : '0' + month;
	day = day > 9 ? day : '0' + day;
	return `${year}-${month}-${day}`;
}
//获取当前时间
const getTime = (type) => {
	const date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours(); // 小时
	let minute = date.getMinutes(); // 分钟
	let second = date.getSeconds(); // 秒数

	month = month > 9 ? month : '0' + month;
	day = day > 9 ? day : '0' + day;
	minute = minute > 9 ? minute : '0' + minute;
	second = second > 9 ? second : '0' + second;
	// 返回日期
	if (type == "date") {
		return `${year}-${month}-${day}`;
	}
	// 返回时间
	if (type == "time") {
		return `${hour}:${minute}:${second}`;
	}
	// 时间日期同时返回
	return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}
// 格式化时间戳
const changeTime = (str) => {
	const date = new Date(Number(str));
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let hour = date.getHours(); // 小时
	let minute = date.getMinutes(); // 分钟
	let second = date.getSeconds(); // 秒数
	month = month > 9 ? month : '0' + month;
	day = day > 9 ? day : '0' + day;
	minute = minute > 9 ? minute : '0' + minute;
	second = second > 9 ? second : '0' + second;
	return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}



export default {
	getDates,
	getTime,
	changeTime
}